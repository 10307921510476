import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { tituloventas, descripciónventas, botonventas, accionbotonventas, infoventasicono1, infoventastexto1, infoventasdatos1, infoventasicono2, infoventastexto2, infoventasdatos2, infoventasicono3, infoventastexto3, infoventasdatos3, mapaventas } from "../Contenido/ContactoContenido"
import { subopciones1 } from "../Contenido/HeaderContenido"

export default function VentaContacto(){
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        vehiculo: '',
        tipopago: '',
        mensaje: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
        if (errors[e.target.id]) {
            setErrors({ ...errors, [e.target.id]: '' });
        }
    };

    const validateForm = () => {
        let tempErrors = {};
        if (!formData.name.trim()) tempErrors.name = "El nombre es requerido";
        if (!formData.email.trim()) tempErrors.email = "El correo es requerido";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = "Correo inválido";
        if (!formData.phone.trim()) tempErrors.phone = "El teléfono es requerido";
        if (!formData.vehiculo) tempErrors.vehiculo = "Debe seleccionar una marca";
        if (!formData.tipopago.trim()) tempErrors.tipopago = "El tipo de pago es requerido";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            emailjs.send(
                'service_rj8o9sc', 
            'template_mid52dp', 
            formData, 
            'VUEwLkPc5RFansL9p'
            )
            .then((result) => {
                console.log(result.text);
                alert('Correo enviado con éxito');
                // Limpiar el formulario
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    vehiculo: '',
                    tipopago: '',
                    mensaje: ''
                });
            }, (error) => {
                console.log(error.text);
                alert('Error al enviar el correo');
            });
        } else {
            console.log("Formulario inválido");
        }
    };

    return(
        <>
        <div className="container-fluid contact">
            <div className="container py-5">
                <div className="row g-4 align-items-center">
                    <div className="col-lg-5 col-xl-5 contact-form wow fadeInLeft" data-wow-delay="0.1s">
                        <h2 className="display-5 text-white mb-2">{tituloventas}</h2>
                        <p className="mb-4 text-white">{descripciónventas}</p>
                        <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-lg-12 col-xl-6">
                                    <div className="form-floating">
                                        <input type="text" className={`form-control bg-transparent border border-white ${errors.name ? 'is-invalid' : ''}`} id="name" placeholder="" value={formData.name} onChange={handleChange}/>
                                        <label htmlFor="name">Nombre</label>
                                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="form-floating">
                                        <input type="email" className={`form-control bg-transparent border border-white ${errors.email ? 'is-invalid' : ''}`} id="email" placeholder="" value={formData.email} onChange={handleChange}/>
                                        <label htmlFor="email">Correo</label>
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="form-floating">
                                        <input type="tel" className={`form-control bg-transparent border border-white ${errors.phone ? 'is-invalid' : ''}`} id="phone" placeholder="" value={formData.phone} onChange={handleChange}/>
                                        <label htmlFor="phone">Teléfono</label>
                                        {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="form-floating">
                                        <select className={`form-select bg-transparent border border-white text-white ${errors.vehiculo ? 'is-invalid' : ''}`} id="vehiculo" value={formData.vehiculo} onChange={handleChange}>
                                            <option value="">Seleccione marca</option>
                                            {subopciones1.map((item, index) => ( 
                                            <option key={index} className="text-dark" value={item.sub1}>{item.sub1}</option>
                                            ))}
                                        </select>
                                        {errors.vehiculo && <div className="invalid-feedback">{errors.vehiculo}</div>}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <select className={`form-select bg-transparent border border-white text-white ${errors.tipopago ? 'is-invalid' : ''}`} id="tipopago" value={formData.tipopago} onChange={handleChange}>
                                            <option value="">Seleccione tipo de pago</option>
                                            <option value="contado" className="text-dark">Contado</option>
                                            <option value="financiamiento" className="text-dark">Financiamiento</option>
                                        </select>
                                        <label htmlFor="tipopago">Tipo de pago</label>
                                        {errors.tipopago && <div className="invalid-feedback">{errors.tipopago}</div>}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea className="form-control bg-transparent border border-white" placeholder="Leave a message here" id="mensaje" value={formData.mensaje} onChange={handleChange}></textarea>
                                        <label htmlFor="mensaje">Comentario</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-light text-primary w-100 py-3">{botonventas}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-2 col-xl-2 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="bg-transparent rounded">
                            <div className="d-flex flex-column align-items-center text-center mb-4">
                                <div className="bg-white d-flex align-items-center justify-content-center mb-3" style={{ width: '90px', height: '90px', borderRadius: '50px' }}><i className={`${infoventasicono1} fa-2x text-primary`}></i></div>
                                <h4 className="text-dark">{infoventastexto1}</h4>
                                {infoventasdatos1.map((item, index)=>(
                                <p className="mb-0 text-white">{item.dato1}</p>
                                ))}
                            </div>
                            <div className="d-flex flex-column align-items-center text-center mb-4">
                                <div className="bg-white d-flex align-items-center justify-content-center mb-3" style={{ width: '90px', height: '90px', borderRadius: '50px' }}><i className={`${infoventasicono2} fa-2x text-primary`}></i></div>
                                <h4 className="text-dark">{infoventastexto2}</h4>
                                {infoventasdatos2.map((item, index)=>(
                                <p className="mb-0 text-white"><a href={item.url2} className="text-white">{item.dato2}</a></p>
                                ))}
                            </div>
                           
                            <div className="d-flex flex-column align-items-center text-center">
                            <div className="bg-white d-flex align-items-center justify-content-center mb-3" style={{ width: '90px', height: '90px', borderRadius: '50px' }}><i className={`${infoventasicono3} fa-2x text-primary`}></i></div>
                                <h4 className="text-dark">{infoventastexto3}</h4>
                                {infoventasdatos3.map((item, index)=>(
                                <p className="mb-0 text-white"><a href={item.url3} className="text-white">{item.dato3}</a></p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-5 wow fadeInRight" data-wow-delay="0.3s">
                        <div className="rounded h-100">
                            <iframe className="rounded w-100" 
                            style={{ height: '500px' }} src={`${mapaventas}`}
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
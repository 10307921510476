export const bannerimagen = 'Equipo.png';
export const bannertitulo = '';

export const misiontitulo = 'MISIÓN';
export const misiontexto = 'Mejorar la vida de nuestros clientes otorgando vehículos para que por medio de ellos puedan generar ingresos y así contribuir al desarrollo económico mientras aumentamos nuestra presencia en el mercado y nuestro alcance. ';
export const visiontitulo = 'VISIÓN';
export const visiontexto = 'Empoderar a miles de familias guatemaltecas al brindar soluciones ágiles y accesibles a taxistas y conductores de Uber permitiéndoles convertirse en dueños de su propio negocio transformando su calidad de vida.';

export const lineatiempo = [
    {
        'imagen': 'Linea1.png',
    },
    {
        'imagen': 'Linea2.png',
    },
]
import React, { useRef } from 'react';
import { titulocontacto, imagencontacto, descripcióncontacto, botonesslide } from "../../components/Contenido/ContactoContenido";
import VentaContacto from '../../components/contacto/VentaContacto';
import RepuestosContacto from '../../components/contacto/RepuestosContacto';
import TallerContacto from '../../components/contacto/TallerContacto';

export default function Contacto() {
  const carouselRef = useRef(null);

  const showSlide = (index) => {
    const carousel = carouselRef.current;
    if (carousel) {
      const bsCarousel = new window.bootstrap.Carousel(carousel, {
        interval: false, // No cambiar automáticamente
        ride: false
      });
      bsCarousel.to(index);
    }
  };

  return (
    <>
      <div className="container-fluid bg-breadcrumb bg-dark" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/design/img/contacto/${imagencontacto})`
      }}>
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h3 className="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s">{titulocontacto}</h3>
        </div>
      </div>

      <div className="container-fluid contact">
        <div className="container py-5">
          <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.2s">
                    <h1 className="display-5 text-white mb-4">{descripcióncontacto}</h1>
                </div>
        </div>
      </div>

        <div className="container-fluid contact">
        <div className="text-center mb-4">
    <div className="d-flex justify-content-center">
      {botonesslide.map((item, index)=>(
        <>
        <button className="btn btn-outline-light mx-2 flex-fill" onClick={() => showSlide(index)}>{item.text}</button>
        </>
      ))}
    </div>
  </div>
            <div id="customCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="false" ref={carouselRef}>
            <div className="carousel-inner">
                <div className="carousel-item active">
                <div className="d-block w-100 bg-primary text-white">
                    <VentaContacto />
                </div>
                </div>
                <div className="carousel-item">
                <div className="d-block w-100 bg-secondary text-white">
                <RepuestosContacto />
                </div>
                </div>
                <div className="carousel-item">
                <div className="d-block w-100 bg-success text-white">
                <TallerContacto />
                </div>
                </div>
            </div>
            </div>
        </div>
    </>
  );
}

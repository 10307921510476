import { titulorepuestos, descripciónrepuestos, botonrepuestos, accionbotonrepuestos, inforepuestosicono1, inforepuestostexto1, inforepuestosdatos1, inforepuestosicono2, inforepuestostexto2, inforepuestosdatos2, inforepuestosicono3, inforepuestostexto3, inforepuestosdatos3, maparepuestos } from "../Contenido/ContactoContenido"
export default function VentaContacto(){
    return(
        <>
        <div className="container-fluid contact">
            <div className="container py-5">
                <div className="row g-4 align-items-center">
                    <div className="col-lg-5 col-xl-5 contact-form wow fadeInLeft" data-wow-delay="0.1s">
                        <h2 className="display-5 text-white mb-2">{titulorepuestos}</h2>
                        <p className="mb-4 text-white">{descripciónrepuestos}</p>
                    </div>
                    <div className="col-lg-2 col-xl-2 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="bg-transparent rounded">
                            <div className="d-flex flex-column align-items-center text-center mb-4">
                                <div className="bg-white d-flex align-items-center justify-content-center mb-3" style={{ width: '90px', height: '90px', borderRadius: '50px' }}><i className={`${inforepuestosicono1} fa-2x text-primary`}></i></div>
                                <h4 className="text-dark">{inforepuestostexto1}</h4>
                                {inforepuestosdatos1.map((item, index)=>(
                                <p className="mb-0 text-white">{item.dato1}<p></p></p>
                                ))}
                            </div>
                            <div className="d-flex flex-column align-items-center text-center mb-4">
                                <div className="bg-white d-flex align-items-center justify-content-center mb-3" style={{ width: '90px', height: '90px', borderRadius: '50px' }}><i className={`${inforepuestosicono2} fa-2x text-primary`}></i></div>
                                <h4 className="text-dark">{inforepuestostexto2}</h4>
                                {inforepuestosdatos2.map((item, index)=>(
                                <p className="mb-0 text-white"><a href={item.url2} className="text-white">{item.dato2}</a></p>
                                ))}
                            </div>
                           
                            <div className="d-flex flex-column align-items-center text-center">
                            <div className="bg-white d-flex align-items-center justify-content-center mb-3" style={{ width: '90px', height: '90px', borderRadius: '50px' }}><i className={`${inforepuestosicono3} fa-2x text-primary`}></i></div>
                                <h4 className="text-dark">{inforepuestostexto3}</h4>
                                {inforepuestosdatos3.map((item, index)=>(
                                <p className="mb-0 text-white"><a href={item.url3} className="text-white">{item.dato3}</a></p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-5 wow fadeInRight" data-wow-delay="0.3s">
                        <div className="rounded h-100">
                            <iframe className="rounded w-100" 
                            style={{ height: '500px' }} src={`${maparepuestos}`}
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
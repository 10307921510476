import { porquetitulo, porquedescripcion, porquecaracteristicas, porqueboton, porquebotonurl } from "../Contenido/InicioContenido"
export default function Porque(){
    return(
        <>
        <div className="container-fluid feature py-5">
            <div className="container py-5">
                <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
                    
                    <h1 className="display-3 mb-4">{porquetitulo}</h1>
                    
                    <p className="mb-0">{porquedescripcion}</p>
                </div>
                <div className="row g-4 justify-content-center">
                {porquecaracteristicas.map((item, index) => (
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="row-cols-1 feature-item p-4">
                            <div className="col-12">
                                <div className="feature-icon mb-4 d-flex justify-content-center">
                                    <div className="p-3 d-inline-flex bg-white rounded">
                                    <img src={`/design/img/inicio/porque/${item.imagen}`} alt="" className="me-2 img-fluid rounded" style={{ width: '150px', height: '150px' }} />
                                    </div>
                                </div>
                                <div className="feature-content d-flex flex-column">
                                    <h5 className="mb-4 text-center">{item.titulo}</h5>
                                    <p className="mb-0">{item.descripcion}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                    <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.2s">
                        <a href={porquebotonurl} className="btn btn-primary rounded-pill text-white py-3 px-5">{porqueboton}</a>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
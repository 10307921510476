import { titulorepuestos, imagenrepuestos, subtitulorepuestos, descripcionrepuestos, ubicacionesrepuestos, botonrepuestostexto, botonrepuestosurl } from "../Contenido/ServiciosContenido"
export default function VentaRepuestos(){
    return(
        <>
        

        <div class="container-fluid p-0">
            <img src={`${process.env.PUBLIC_URL}/design/img/servicios/repuestos/${imagenrepuestos}`} class="img-fluid w-100" alt="Descripción de la imagen"/>
        </div>


        <div className="container-fluid about bg-light py-5">
            <div className="container">
                <div className="text-center mb-5 wow fadeInUp" data-wow-delay="0.2s">
                    <h1 className="display-6 text-info mb-4">{subtitulorepuestos}</h1>
                </div>
            </div>
            <div className="row m-5">
                <h2>{descripcionrepuestos}</h2>
                <table className="table table-responsive ">
                    <thead>
                    <tr>
                        <th>Tienda</th>
                        <th>Dirección</th>
                        <th>Teléfono</th>
                        <th colSpan={2}>Ubicación</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ubicacionesrepuestos.map((item, index) => (
                    <tr className="align-middle">
                    <td><img src={`/design/img/servicios/repuestos/${item.foto}`} alt="" className="me-2" style={{ width: '80px', height: '80px' }} /></td>
                        <td>{item.direccion}</td>
                    <td><a href={item.urltelefono}>{item.telefono}</a></td>
                    <td>
                    <a href={item.urlwaze}> <img src={`/design/img/Generales/iconowaze.png`} alt="Waze" className="me-2" style={{ width: '50px', height: '50px' }} /></a>
                    </td>
                    <td>
                    <a href={item.urlmaps}><img src={`/design/img/Generales/iconomaps.png`} alt="Google Maps" style={{ width: '50px', height: '50px' }} /></a>
                    </td>
                    </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="row g-5 justify-content-center text-center">
                    <div className="">
                    <a href={botonrepuestosurl} className="btn btn-primary rounded-pill text-white py-3 px-5">{botonrepuestostexto}</a>
                    </div>
                </div>
        </div>
        </>
    )
}
import { marcanombre, marcatitulo, marcadescripcion, marcas } from "../Contenido/InicioContenido"
import { Link } from "react-router-dom";
export default function Marcas(){
    return(
        <>
        <div className="container-fluid service py-5">
            <div className="container py-5">
                <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.2s">
                    <div className="sub-style">
                        <h4 className="sub-title px-3 mb-0">{marcanombre}</h4>
                    </div>
                    <h1 className="display-5 mb-4">{marcatitulo}</h1>
                    <p className="mb-0">{marcadescripcion}</p>
                </div>
                <div className="row g-4 justify-content-center">
                {marcas.map((item, index) => (
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s" key={index}>
                        
                        <a href={item.url}>
                            <div className="service-item rounded">
                            <div className="service-img rounded-top">
                                    <img src={`/design/img/inicio/marcas/${item.imagen}`} className="img-fluid rounded-top w-100" alt=""/>
                            </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">{item.titulo}</h5>
                                        <p className="mb-4">{item.descripcion}</p>
                                        
                                    </div>
                                </div>
                            </div>
                            </a>
                        
                    </div>
                    ))}
                    
                    
                </div>
            </div>
        </div>
        </>
    )
}
import { bannertitulo, bannerimagen, lineatiempo, misiontexto, misiontitulo, visiontexto, visiontitulo } from "../../components/Contenido/AboutContenido"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function About(){

    const options = {
        items: 1,
        loop: true,
        nav: true,
        dots: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        navText: ['<span class="owl-prev-custom">&#10094;</span>', '<span class="owl-next-custom">&#10095;</span>'],
      };

    return(
        <>
        

        <div class="container-fluid p-0">
            <img src={`${process.env.PUBLIC_URL}/design/img/about/banner/${bannerimagen}`} class="img-fluid w-100" alt="Descripción de la imagen"/>
        </div>

        <div className="container my-5">
  <div className="row">
    <div className="col-md-6 mb-4">
      <div className="card h-100 shadow-sm">
        <div className="card-body">
          <h3 className="card-title text-primary">{misiontitulo}</h3>
          <p className="card-text">
            {misiontexto}
          </p>
        </div>
      </div>
    </div>
    <div className="col-md-6 mb-4">
      <div className="card h-100 shadow-sm">
        <div className="card-body">
          <h3 className="card-title text-primary">{visiontitulo}</h3>
          <p className="card-text">
          {visiontexto}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

        <div className="container my-5">
      <OwlCarousel className="owl-theme" {...options}>
        {lineatiempo.map((item, index) => (
          <div key={index} className="item">
            <img src={`/design/img/about/lineadetiempo/${item.imagen}`} alt={`Slide ${index + 1}`} className="img-fluid" />
          </div>
        ))}
      </OwlCarousel>
    </div>
        </>
    )
}
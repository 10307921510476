import Carousel from "../../components/inicio/Carousel"
import Marcas from "../../components/inicio/Marcas"
import Financiamiento from "../../components/inicio/Financiamientos"
import Porque from "../../components/inicio/Porque"
import Testimonios from "../../components/inicio/Testimonio"
export default function Inicio(){
    return(
        <>
           <Carousel/>
        <Marcas/>
        <Financiamiento/>
        <Porque/>
        <Testimonios/>
        </>
    )
}
import { imagentaller, titulotaller, subtitulotaller, descripciontaller, ubicacionestaller, botontallertexto, botontallerurl } from "../Contenido/ServiciosContenido"
export default function Taller(){
    return(
        <>
        
        <div class="container-fluid p-0">
            <img src={`${process.env.PUBLIC_URL}/design/img/servicios/taller/${imagentaller}`} class="img-fluid w-100" alt="Descripción de la imagen"/>
        </div>


        <div className="container-fluid about py-5">
            <div className="container text-center">
                <div className="text-center wow fadeInUp" data-wow-delay="0.2s">
                    <h1 className="display-6 text-info mb-4">{subtitulotaller}</h1>
                </div>
                <h2>{descripciontaller}</h2>
            </div>
            <div className="row m-5">
                <table className="table table-responsive">
                    <thead>
                    <tr>
                        <th>Taller</th>
                        <th>Dirección</th>
                        <th>Teléfono</th>
                        <th colSpan={2}>Ubicación</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ubicacionestaller.map((item, index) => (
                    <tr className="align-middle">
                    <td><img src={`/design/img/servicios/taller/${item.foto}`} alt="" className="me-2" style={{ width: '80px', height: '80px' }} /></td>
                        <td>{item.direccion}</td>
                    <td><a href={item.urltelefono}>{item.telefono}</a></td>
                    <td>
                    <a href={item.urlwaze}> <img src={`/design/img/Generales/iconowaze.png`} alt="Waze" className="me-2" style={{ width: '50px', height: '50px' }} /></a>
                    </td>
                    <td>
                    <a href={item.urlmaps}><img src={`/design/img/Generales/iconomaps.png`} alt="Google Maps" style={{ width: '50px', height: '50px' }} /></a>
                    </td>
                    </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="row g-5 justify-content-center text-center">
                    <div className="">
                    <a href={botontallerurl} className="btn btn-primary rounded-pill text-white py-3 px-5">{botontallertexto}</a>
                    </div>
                </div>
        </div>
        </>
    )
}

import { carousel } from '../Contenido/InicioContenido';
export default function Carousel(){
    const options = {
        items: 1,
        loop: true,
        nav: true,
        autoplay: true,
        // Puedes agregar más opciones aquí según tus necesidades
      };

      
    return(
        <>
        
       


        <div id="carouselExampleIndicators" 
     className="carousel slide" 
     data-bs-ride="carousel" 
     style={{position: 'relative'}}
>
  <div className="d-none d-lg-block" style={{marginTop: '80px'}}></div>
  <ol className="carousel-indicators">
    {carousel.map((item, index) => (
      <li
        key={index}
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to={index}
        className={index === 0 ? 'active' : ''}
      ></li>
    ))}
  </ol>
  <div className="carousel-inner">
    {carousel.map((item, index) => (
      <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
        <img src={`/design/img/inicio/carousel/${item.imagen}`} className="img-fluid w-100" alt={`Carousel Image ${index}`} />
        <div class="carousel-caption d-none d-md-block">
            <h5 className="text-white text-uppercase fw-bold mb-4" style={{ letterSpacing: '3px' }}>{item.texto1}</h5>
              <h1 className="display-1 text-capitalize text-white mb-4">{item.texto2}</h1>
              <p className="mb-5 fs-5">{item.texto3}</p>
        </div>
      </div>
    ))}
  </div>
  <a
  className="carousel-control-prev"
  href="#carouselExampleIndicators"
  role="button"
  data-bs-slide="prev"
  style={{
    transition: 'box-shadow 0.3s ease',
  }}
  onMouseEnter={(e) => {
    e.currentTarget.style.boxShadow = '0px 0px 15px 5px rgba(255, 255, 255, 0.7)';
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.boxShadow = '0px 0px 15px 5px rgba(255, 255, 255, 0)';
  }}
>
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="sr-only">Previous</span>
</a>
<a
  className="carousel-control-next"
  href="#carouselExampleIndicators"
  role="button"
  data-bs-slide="next"
  style={{
    transition: 'box-shadow 0.3s ease',
  }}
  onMouseEnter={(e) => {
    e.currentTarget.style.boxShadow = '0px 0px 15px 5px rgba(255, 255, 255, 0.7)';
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.boxShadow = '0px 0px 15px 5px rgba(255, 255, 255, 0)';
  }}
>
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="sr-only">Next</span>
</a>
</div>


        </>
    )
}
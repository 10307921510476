export const vehiculosmodelos = [
    {
        'id': 1,
        'vehiculo': 'Honda Fit',
        'modelo': '2007 - 2008',
        'banner': 'HondaFit2007-2008.png',
        'galeria': [
            {
                'imagen': 'HondaFit2007-2008.png',
            },
            {
                'imagen': 'HondaFit2007-2008Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'HondaFit2007-2008.png',
    },
    {
        'id': 2,
        'vehiculo': 'Honda Fit',
        'modelo': '2009 - 2010',
        'banner': 'HondaFit2009-2010.png',
        'galeria': [
            {
                'imagen': 'HondaFit2009-2010.png',
            },
            {
                'imagen': 'HondaFit2009-2010Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'HondaFit2009-2010.png',
    },
    {
        'id': 3,
        'vehiculo': 'Honda Fit',
        'modelo': '2011 - 2012',
        'banner': 'HondaFit2011-2012.png',
        'galeria': [
            {
                'imagen': 'HondaFit2011-2012.png',
            },
            {
                'imagen': 'HondaFit2011-2012Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'HondaFit2011-2012.png',
    },
    {
        'id': 4,
        'vehiculo': 'Honda Fit',
        'modelo': '2013',
        'banner': 'HondaFit2013.png',
        'galeria': [
            {
                'imagen': 'HondaFit2013.png',
            },
            {
                'imagen': 'HondaFit2013Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'HondaFit2013.png',
    },
    {
        'id': 5,
        'vehiculo': 'Hyundai Accent',
        'modelo': '2012 - 2013',
        'banner': 'Accent2012-2013.png',
        'galeria': [
            {
                'imagen': 'HyundaiAccent2012-2013.png',
            },
            {
                'imagen': 'HyundaiAccent2012-2013Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'HyundaiAccent2012-2013.png',
    },
    {
        'id': 6,
        'vehiculo': 'Hyundai Accent',
        'modelo': '2014 - 2015',
        'banner': 'Accent2014-2015.png',
        'galeria': [
            {
                'imagen': 'HyundaiAccent2014-2015.png',
            },
            {
                'imagen': 'HyundaiAccent2014-2015Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'HyundaiAccent2014-2015.png',
    },
    {
        'id': 7,
        'vehiculo': 'Hyundai Accent',
        'modelo': '2016 - 2017',
        'banner': 'Accent2016-2017.png',
        'galeria': [
            {
                'imagen': 'HyundaiAccent2016-2017.png',
            },
            {
                'imagen': 'HyundaiAccent2016-2017Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'HyundaiAccent2016-2017.png',
    },
    {
        'id': 8,
        'vehiculo': 'Kia Rio',
        'modelo': '2012 - 2013',
        'banner': 'KiaRio2012-2013.png',
        'galeria': [
            {
                'imagen': 'KiaRio2012-2013.png',
            },
            {
                'imagen': 'KiaRio2012-2013Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'KiaRio2012-2013.png',
    },
    {
        'id': 9,
        'vehiculo': 'Kia Rio',
        'modelo': '2014 - 2015',
        'banner': 'KiaRio2014-2015.png',
        'galeria': [
            {
                'imagen': 'KiaRio2014-2015.png',
            },
            {
                'imagen': 'KiaRio2014-2015Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'KiaRio2014-2015.png',
    },
    {
        'id': 10,
        'vehiculo': 'Kia Rio',
        'modelo': '2016 - 2017',
        'banner': 'KiaRio2016-2017.png',
        'galeria': [
            {
                'imagen': 'KiaRio2016-2017.png',
            },
            {
                'imagen': 'KiaRio2016-2017Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'KiaRio2016-2017.png',
    },
    {
        'id': 11,
        'vehiculo': 'Mazda 2',
        'modelo': '2011 - 2012',
        'banner': 'Mazda22011-2012.png',
        'galeria': [
            {
                'imagen': 'Mazda22011-2012.png',
            },
            {
                'imagen': 'Mazda22011-2012Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'Mazda22011-2012.png',
    },
    {
        'id': 11,
        'vehiculo': 'Mazda 2',
        'modelo': '2013 - 2014',
        'banner': 'Mazda22013-2014.png',
        'galeria': [
            {
                'imagen': 'Mazda22013-2014.png',
            },
            {
                'imagen': 'Mazda22013-2014Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'Mazda22013-2014.png',
    },
    {
        'id': 13,
        'vehiculo': 'Scion IA',
        'modelo': '2016 - 2018',
        'banner': 'ScionIA2016-2018.png',
        'galeria': [
            {
                'imagen': 'ScionIA2016-2018.png',
            },
            {
                'imagen': 'ScionIA2016-2018Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'ScionIA2016-2018.png',
    },
    {
        'id': 14,
        'vehiculo': 'Scion XA',
        'modelo': '2004 - 2006',
        'banner': 'ScionXA2004-2006.png',
        'galeria': [
            {
                'imagen': 'ScionXA2004-2006.png',
            },
            {
                'imagen': 'ScionXA2004-2006Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'ScionXA2004-2006.png',
    },
    {
        'id': 15,
        'vehiculo': 'Scion XB',
        'modelo': '2004 - 2006',
        'banner': 'ScionXB2004-2006.png',
        'galeria': [
            {
                'imagen': 'ScionXB2004-2006.png',
            },
            {
                'imagen': 'ScionXB2004-2006Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'ScionXB2004-2006.png',
    },
    {
        'id': 16,
        'vehiculo': 'Scion XD',
        'modelo': '2008 - 2009',
        'banner': 'ScionXB2008-2009.png',
        'galeria': [
            {
                'imagen': 'ScionXB2008-2009.png',
            },
            {
                'imagen': 'ScionXB2008-2009Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'ScionXB2008-2009.png',
    },
    {
        'id': 17,
        'vehiculo': 'Scion XD',
        'modelo': '2010 - 2011',
        'banner': 'ScionXB2010-2011.png',
        'galeria': [
            {
                'imagen': 'ScionXB2010-2011.png',
            },
            {
                'imagen': 'ScionXB2010-2011Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'ScionXB2010-2011.png',
    },
    {
        'id': 18,
        'vehiculo': 'Scion XD',
        'modelo': '2012 - 2013',
        'banner': 'ScionXB2012-2013.png',
        'galeria': [
            {
                'imagen': 'ScionXB2012-2013.png',
            },
            {
                'imagen': 'ScionXB2012-2013Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'ScionXB2012-2013.png',
    },
    {
        'id': 19,
        'vehiculo': 'Toyota Yaris',
        'modelo': '2007 - 2008',
        'banner': 'Yaris2007-2008.png',
        'galeria': [
            {
                'imagen': 'ToyotaYaris2007-2008.png',
            },
            {
                'imagen': 'ToyotaYaris2007-2008Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'ToyotaYaris2007-2008.png',
    },
    {
        'id': 20,
        'vehiculo': 'Toyota Yaris',
        'modelo': '2009 - 2010',
        'banner': 'Yaris2009-2010.png',
        'galeria': [
            {
                'imagen': 'ToyotaYaris2009-2010.png',
            },
            {
                'imagen': 'ToyotaYaris2009-2010Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'ToyotaYaris2009-2010.png',
    },
    {
        'id': 21,
        'vehiculo': 'Toyota Yaris',
        'modelo': '2011 - 2012',
        'banner': 'Yaris2011-2012.png',
        'galeria': [
            {
                'imagen': 'ToyotaYaris2011-2012.png',
            },
            {
                'imagen': 'ToyotaYaris2011-2012Back.png',
            },
        ],
        'descripcion': 'Descripcion del vehículo que se quiera colocar',
        'textoboton': 'Acción', 
        'urlboton': '',
        'fichatecnica': 'ToyotaYaris2011-2012.png',
    },
]
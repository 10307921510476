import { titulotaller, descripcióntaller, botontaller, accionbotontaller, infotallericono1, infotallertexto1, infotallerdatos1, infotallericono2, infotallertexto2, infotallerdatos2, infotallericono3, infotallertexto3, infotallerdatos3, mapataller } from "../Contenido/ContactoContenido"
export default function VentaContacto(){
    return(
        <>
        <div className="container-fluid contact">
            <div className="container py-5">
                <div className="row g-4 align-items-center">
                    <div className="col-lg-5 col-xl-5 contact-form wow fadeInLeft" data-wow-delay="0.1s">
                        <h2 className="display-5 text-white mb-2">{titulotaller}</h2>
                        <p className="mb-4 text-white">{descripcióntaller}</p>
                        
                    </div>
                    <div className="col-lg-2 col-xl-2 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="bg-transparent rounded">
                            <div className="d-flex flex-column align-items-center text-center mb-4">
                                <div className="bg-white d-flex align-items-center justify-content-center mb-3" style={{ width: '90px', height: '90px', borderRadius: '50px' }}><i className={`${infotallericono1} fa-2x text-primary`}></i></div>
                                <h4 className="text-dark">{infotallertexto1}</h4>
                                {infotallerdatos1.map((item, index)=>(
                                <p className="mb-0 text-white">{item.dato1}</p>
                                ))}
                            </div>
                            <div className="d-flex flex-column align-items-center text-center mb-4">
                                <div className="bg-white d-flex align-items-center justify-content-center mb-3" style={{ width: '90px', height: '90px', borderRadius: '50px' }}><i className={`${infotallericono2} fa-2x text-primary`}></i></div>
                                <h4 className="text-dark">{infotallertexto2}</h4>
                                {infotallerdatos2.map((item, index)=>(
                                <p className="mb-0 text-white"><a href={item.url2} className="text-white">{item.dato2}</a></p>
                                ))}
                            </div>
                           
                            <div className="d-flex flex-column align-items-center text-center">
                            <div className="bg-white d-flex align-items-center justify-content-center mb-3" style={{ width: '90px', height: '90px', borderRadius: '50px' }}><i className={`${infotallericono3} fa-2x text-primary`}></i></div>
                                <h4 className="text-dark">{infotallertexto3}</h4>
                                {infotallerdatos3.map((item, index)=>(
                                <p className="mb-0 text-white"><a href={item.url3} className="text-white">{item.dato3}</a></p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-5 wow fadeInRight" data-wow-delay="0.3s">
                        <div className="rounded h-100">
                            <iframe className="rounded w-100" 
                            style={{ height: '500px' }} src={`${mapataller}`}
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
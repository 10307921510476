import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./pages/layout/layout";
import Inicio from "./pages/inicio/inicio";
import About from "./pages/about/about";
import Servicios from "./pages/servicios/servicios";
import Contacto from "./pages/contacto/contacto";
import Marca from "./pages/marca/marca";
import Vehiculo from "./pages/vehiculo/vehiculo";
import './design/css/bootstrap.min.css';
import './design/css/style.css';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} >
      <Route path="/" element={<Inicio />} />
      <Route path="sobrenosotros" element={<About />} />
      <Route path="servicios" element={<Servicios />} />
      <Route path="contacto" element={<Contacto />} />
      <Route path="marca/:marca" element={<Marca />} />
      <Route path="vehiculo/:vehiculo/:modelo" element={<Vehiculo />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
      </Routes>
  );
}

export default App;

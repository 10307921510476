import { financiamientotitulo, financiamientodescripcion, imagen1, imagen2, financiamientos, textoboton, urlboton } from "../Contenido/InicioContenido"
export default function Financiamiento(){
    return(
        <>
        <div className="container-fluid about bg-light py-5">
            <div className="container py-5">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-5 wow fadeInLeft" data-wow-delay="0.2s">
                        <div className="about-img pb-5 ps-5">
                            <img src={`/design/img/inicio/financiamiento/${imagen2}`} className="img-fluid rounded w-100" style={{ objectFit: 'cover' }} alt=""/>
                            <div className="about-img-inner">
                                <img src={`/design/img/inicio/financiamiento/${imagen1}`} className="img-fluid rounded-circle w-50 h-50" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 wow fadeInRight" data-wow-delay="0.4s">
                        <div className="section-title text-start mb-5">
                            
                            <h1 className="display-3 mb-4">{financiamientotitulo}</h1>
                            <p className="mb-4">{financiamientodescripcion}</p>
                            <div className="mb-4">
                            {financiamientos.map((item, index) => (
                                <>
                                <p className="text-info"><i className="fa fa-check text-primary me-2"></i> {item.financiamiento}</p>
                                </>
                            ))}
                            </div>
                            <a href={urlboton} className="btn btn-primary rounded-pill text-white py-3 px-5">{textoboton}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
import { ubicacipontexto, ubicaciponurl, telefonotexto, telefonourl, correotexto, correourl, redessociales, logo, nombre, urllogo, opcion1, opcion2, opcion3, opcion4, opcion1url, opcion2url, opcion3url, opcion4url, subopciones1 } from "../Contenido/HeaderContenido"
import { useNavigate, Link } from "react-router-dom";

export default function Header(){

    const navigate = useNavigate();
    
    return(
        <>
       


        <div className="container-fluid bg-dark px-5 d-none d-lg-block">
            <div className="row gx-0 align-items-center" style={{ height: '45px' }}>
                <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                    <div className="d-flex flex-wrap">
                        <a href={ubicaciponurl} className="text-light me-4"><i className="fas fa-map-marker-alt text-primary me-2"></i>{ubicacipontexto}</a>
                        <a href={telefonourl} className="text-light me-4"><i className="fas fa-phone-alt text-primary me-2"></i>{telefonotexto}</a>
                        <a href={correourl} className="text-light me-0"><i className="fas fa-envelope text-primary me-2"></i>{correotexto}</a>
                    </div>
                </div>
                <div className="col-lg-4 text-center text-lg-end">
                    <div className="d-flex align-items-center justify-content-end">
                    {redessociales.map((item, index) => (
                        <>
                        <a href={item.url} className="btn btn-light btn-square border rounded-circle nav-fill me-3"><i className={item.logo}></i></a>
                        </>
                    ))}
                    </div>
                </div>
            </div>
        </div>


        <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0">
                <a href={() => false}  onClick={() => navigate(`${urllogo}`)} className="navbar-brand p-0 cursor-active">
                <img src={`/design/img/Generales/${logo}`} alt="BryAuto Logo" className="img-fluid"  />
                
                <img src={`/design/img/Generales/${nombre}`} alt="BryAuto Logo" className="img-fluid w-50" />
                    
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <div className="nav-item dropdown">
                            <a href={opcion1url} className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{opcion1}</a>
                            <div className="dropdown-menu m-0">
                            {subopciones1.map((item, index) => ( 
                                <>
                                
                                <a href={item.url} className="dropdown-item cursor-active">
                                {item.sub1}
                                 </a>
                                </>
                            ))}
                            </div>
                        </div>
                        <a href={opcion2url} className="nav-item nav-link cursor-active">{opcion2}</a>
                        <a href={opcion3url} className="nav-item nav-link cursor-active">{opcion3}</a>
                        <a href={opcion4url} className="nav-item nav-link cursor-active">{opcion4}</a>
                    </div>
                   
                </div>
            </nav>
        </div>

        

        </>
    )
}
export const tituloservicios = '';
export const imagenservicios = 'BannerServicios.png';



////////////////Ventas
export const tituloventas = '';
export const imagenventas = 'BannerVentas.png';

export const descripcionventas = 'Contamos con venta de vehículos financiados y al contado.';

export const titulocol1 = 'Vehículos financiados:';
export const descripcion1col1 = 'Son aquellos que se trabajan por medio de un enganche, cuotas semanales o mensuales, y tiempo de espera para recibir tu vehículo.';
export const descripcion2col1 = 'Los requisitos para la venta financiada son:';

export const detallescol1 = [
    {
        'detalle': 'DPI',
    },
    {
        'detalle': 'NIT',
    },
    {
        'detalle': 'Licencia de conducir',
    },
    {
        'detalle': 'Recibo de Agua',
    },
    {
        'detalle': 'Recibo de Luz',
    },

]


export const titulocol2 = 'Vehículos de contado:';
export const descripcion1col2 = 'Son vehículos listos para entrega inmediata pagando el total del precio establecido.';
export const descripcion2col2 = 'Los requisitos para la venta de contado son:';

export const detallescol2 = [
    {
        'detalle': 'El traspaso corre por cuenta del cliente',
    },
    {
        'detalle': 'Pago total (Efectivo, Transferencia, Depósito o Tarjeta de crédito)',
    },

]

export const botonventastexto = 'Más Información';
export const botonventasurl = 'https://drive.google.com/file/d/1mw6O8Lj0VASY2dg7up2ce6orfaJqMP2a/view?usp=sharing ';


////////////////////////Venta de Repuestos
export const titulorepuestos = '';
export const imagenrepuestos = 'BannerRepuestos.png';

export const subtitulorepuestos = 'Contamos con una gama amplia de repuestos nuevos y de calidad para las líneas: Hyundai Accent, Honda Fit, Kia Rio, Scion XA, Scion XB, Scion XD y Toyota Yaris.';
export const descripcionrepuestos = 'Contamos con 3 tiendas para poder brindarte el mejor servicio.';

export const ubicacionesrepuestos = [
    {
        'foto': 'Zona3Mixco.png',
        'direccion': 'Calzada San Juan 14-56 Colonia Nueva Montserrat, Zona 3 de Mixco',
        'telefono': '5865 5172',
        'urltelefono': 'https://wa.me/+50258655172',
        'urlwaze': 'https://ul.waze.com/ul?place=ChIJJWG-SsqhiYURylPXNzequOk&ll=14.64044700%2C-90.56774700&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        'urlmaps': 'https://maps.app.goo.gl/8kZwGhRsAeQqrkdy6',
    },
    {
        'foto': 'Zona4Mixco.png',
        'direccion': 'Calzada San Juan 0-41 Montserrat 1, Zona 4 de Mixco',
        'telefono': '5482 9191',
        'urltelefono': 'https://wa.me/+50254829191',
        'urlwaze': 'https://ul.waze.com/ul?place=ChIJe2qm0dOhiYURuLIprsXv7UY&ll=14.64918100%2C-90.58236800&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        'urlmaps': 'https://maps.app.goo.gl/pQDFqitHmEBtk7Xc9',
    },
    {
        'foto': 'TiendaZona11.png',
        'direccion': '10av A 33-83 Zona 11, Colonia Las Charcas, Ciudad de Guatemala',
        'telefono': '5515 3484',
        'urltelefono': 'https://wa.me/+50255153484',
        'urlwaze': 'https://waze.com/ul/h9fxe50uu2',
        'urlmaps': 'https://maps.app.goo.gl/7tVsm9ixb9QdWxoz8',
    },

]

export const botonrepuestostexto = 'Más Información';
export const botonrepuestosurl = 'https://wa.me/+50258655172';



////////////////////////Taller
export const titulotaller = '';
export const imagentaller = 'BannerTaller.png';

export const subtitulotaller = 'En el taller de Bryauto encontrarás un equipo de mecánicos profesionales para el mantenimiento de tu vehículo.';
export const descripciontaller = 'Ofreciéndote todo tipo de servicios, brindándote una buena atención y precios accesibles para tu comodidad.';

export const ubicacionestaller = [
    {
        'foto': 'TiendaZona11.png',
        'direccion': '10av A 33-83 Zona 11, Colonia Las Charcas, Ciudad de Guatemala',
        'telefono': '4220-7431',
        'urltelefono': '',
        'urlwaze': '',
        'urlmaps': '',
    },

]

export const botontallertexto = 'Agenda tu Cita';
export const botontallerurl = 'https://wa.me/+50242207431';
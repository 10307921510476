import { videotestimonios, testimoniotitulo } from "../Contenido/InicioContenido"

export default function Testimonios(){
    
     

    return(
        <>
        <div className="container">
            {/* Primera fila para el título */}
            <div className="row">
                <div className="col text-center">
                    <h2>{testimoniotitulo}</h2>
                </div>
            </div>

            {/* Segunda fila para el video */}
            <div className="row mt-4">
                <div className="col">
                    <video className="w-100" controls controlsList="nodownload" style={{ position: 'relative', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}>
                        <source src={`/design/img/inicio/testimonios/${videotestimonios}`} type="video/mp4" />
                        Tu navegador no soporta la etiqueta de video.
                    </video>
                </div>
            </div>
        </div>
        
        </>
    )
}
import { useParams } from 'react-router-dom';
import { vehiculosmodelos } from '../../components/Contenido/VehiculoContenido';
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
export default function Vehiculo(){
    const { vehiculo, modelo } = useParams();

    const vehiculosFiltrados = vehiculosmodelos.filter(carro => carro.vehiculo === vehiculo && carro.modelo === modelo);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        vehiculo: `${vehiculo} ${modelo}`,
        tipopago: '',
        mensaje: ''
      });
    
      const [errors, setErrors] = useState({});
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
        // Limpiar el error cuando el usuario empieza a escribir
        if (errors[e.target.id]) {
          setErrors({ ...errors, [e.target.id]: '' });
        }
      };
    
      const validateForm = () => {
        let tempErrors = {};
        if (!formData.name.trim()) tempErrors.name = "El nombre es requerido";
        if (!formData.email.trim()) tempErrors.email = "El correo es requerido";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = "Correo inválido";
        if (!formData.phone.trim()) tempErrors.phone = "El teléfono es requerido";
        if (!formData.tipopago) tempErrors.tipopago = "Debe seleccionar un tipo de pago";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
          emailjs.send(
            'service_rj8o9sc', 
            'template_mid52dp', 
            formData, 
            'VUEwLkPc5RFansL9p'
          )
            .then((result) => {
              console.log(result.text);
              alert('Correo enviado con éxito');
              // Limpiar el formulario
              setFormData({
                name: '',
                email: '',
                phone: '',
                vehiculo: `${vehiculo} ${modelo}`,
                tipopago: '',
                mensaje: ''
              });
            }, (error) => {
              console.log(error.text);
              alert('Error al enviar el correo');
            });
        } else {
          console.log("Formulario inválido");
        }
      };

    return(
        <>
        {vehiculosFiltrados.map((item, index)=>(
            <>
         

<div class="container-fluid p-0 mt-5">
            <img src={`${process.env.PUBLIC_URL}/design/img/vehiculo/banner/${item.banner}`} class="img-fluid w-100" alt="Descripción de la imagen"/>
        </div>

    
<div className="container-fluid my-5">
        <div className="row">
            
            <div className="col-md-6">
                <div id="vehicleCarousel" className="carousel slide m-5" data-bs-ride="carousel">
                    <div className="carousel-inner">
                    {item.galeria.map((gal, index)=>(
                        <>
                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                            <img src={`/design/img/vehiculo/galeria/${gal.imagen}`} className="d-block w-100" alt="Imagen"/>
                        </div>
                        </>
                    ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#vehicleCarousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Anterior</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#vehicleCarousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Siguiente</span>
                    </button>
                </div>
            </div>
           
            
            <div className="col-md-6 d-flex align-items-center">
            <img src={`/design/img/vehiculo/fichastecnicas/${item.fichatecnica}`} class="img-fluid" alt="Ficha Técnica del Vehículo"/>
            </div>
        </div>
    </div>

    
    

    
    <section className="my-5">
        <div className="container">
            <h2 className="text-center">Cotiza tu {vehiculo} {modelo}</h2>
            <div className="card mt-4">
                <div className="card-body">
                    <h5 className="card-title">Te Contáctaremos lo más pronto posible.</h5>
                     <form onSubmit={handleSubmit}>
      <div className="row g-3">
        <div className="col-lg-12 col-xl-6">
          <div className="form-floating">
            <input 
              type="text" 
              className={`form-control bg-transparent border ${errors.name ? 'is-invalid' : ''}`}
              id="name" 
              placeholder=""
              value={formData.name}
              onChange={handleChange}
            />
            <label htmlFor="name">Nombre</label>
            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
          </div>
        </div>
        <div className="col-lg-12 col-xl-6">
          <div className="form-floating">
            <input 
              type="email" 
              className={`form-control bg-transparent border ${errors.email ? 'is-invalid' : ''}`}
              id="email" 
              placeholder=""
              value={formData.email}
              onChange={handleChange}
            />
            <label htmlFor="email">Correo</label>
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>
        </div>
        <div className="col-lg-12 col-xl-6">
          <div className="form-floating">
            <input 
              type="tel" 
              className={`form-control bg-transparent border ${errors.phone ? 'is-invalid' : ''}`}
              id="phone" 
              placeholder=""
              value={formData.phone}
              onChange={handleChange}
            />
            <label htmlFor="phone">Teléfono</label>
            {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
          </div>
        </div>
        <div className="col-lg-12 col-xl-6">
          <div className="form-floating">
            <input 
              type="text" 
              className="form-control bg-transparent border"
              id="vehiculo" 
              placeholder="" 
              value={formData.vehiculo}
              readOnly
            />
            <label htmlFor="vehiculo">Vehículo</label>
          </div>
        </div>
        <div className="col-12">
  <div className="form-floating">
    <select
      className={`form-select bg-transparent border ${errors.tipopago ? 'is-invalid' : ''}`}
      id="tipopago"
      value={formData.tipopago}
      onChange={handleChange}
    >
      <option value="">Seleccione un tipo de pago</option>
      <option value="contado">Contado</option>
      <option value="financiamiento">Financiamiento</option>
    </select>
    <label htmlFor="tipopago">Tipo de pago</label>
    {errors.tipopago && <div className="invalid-feedback">{errors.tipopago}</div>}
  </div>
</div>
        <div className="col-12">
          <div className="form-floating">
            <textarea 
              className="form-control bg-transparent border" 
              placeholder="Deja un mensaje aquí" 
              id="mensaje"
              value={formData.mensaje}
              onChange={handleChange}
            ></textarea>
            <label htmlFor="mensaje">Comentario</label>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button type="submit" className="btn btn-light w-25 text-primary py-3 border">Enviar</button>
        </div>
      </div>
    </form>
                </div>
            </div>
            
        </div>
    </section>
    </>
        ))}
        </>
    )
}
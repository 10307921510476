import VentaVehiculos from "../../components/servicios/VentaVehiculos"
import VentaRepuestos from "../../components/servicios/VentaRepuestos"
import Taller from "../../components/servicios/Taller"
import { tituloservicios, imagenservicios } from "../../components/Contenido/ServiciosContenido"
export default function Servicios(){
    return(
        <>
        <div class="container-fluid p-0">
            <img src={`${process.env.PUBLIC_URL}/design/img/servicios/${imagenservicios}`} class="img-fluid w-100" alt="Descripción de la imagen"/>
        </div>
        <VentaVehiculos/>
        <VentaRepuestos/>
        <Taller/>
        </>
    )
}
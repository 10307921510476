


export const encabezadomarca = [
    {
        'marca': 'Honda',
        'imagenmarcaencabezado': '',
    },
    {
        'marca': 'Mazda',
        'imagenmarcaencabezado': '',
    },
    {
        'marca': 'Kia',
        'imagenmarcaencabezado': '',
    },
    {
        'marca': 'Toyota',
        'imagenmarcaencabezado': '',
    },
    {
        'marca': 'Hyundai',
        'imagenmarcaencabezado': '',
    },
    {
        'marca': 'Scion',
        'imagenmarcaencabezado': '',
    },
]
export const modelosmarcas = [
    {
        'id': 1,
        'marca': 'Honda',
        'titulolinea': 'Honda Fit',
        'modelos': [
            {
                'imagen': 'Honda Fit 2007-2008.png',
                'titulo': 'Honda Fit',
                'modelo': '2007 - 2008',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Honda Fit 2009-2010.png',
                'titulo': 'Honda Fit',
                'modelo': '2009 - 2010',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Honda Fit 2011-2012.png',
                'titulo': 'Honda Fit',
                'modelo': '2011 - 2012',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Honda Fit 2013.png',
                'titulo': 'Honda Fit',
                'modelo': '2003',
                'url': 'vehiculo',
            },
        ]
    },
    {
        'id': 2,
        'marca': 'Hyundai',
        'titulolinea': 'Hyundai Accent',
        'modelos': [
            {
                'imagen': 'Hyundai Accent 2012-2013.png',
                'titulo': 'Hyundai Accent',
                'modelo': '2012 - 2013',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Hyundai Accent 2014-2015.png',
                'titulo': 'Hyundai Accent',
                'modelo': '2014 - 2015',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Hyundai Accent 2016-2017.png',
                'titulo': 'Hyundai Accent',
                'modelo': '2016 - 2017',
                'url': 'vehiculo',
            },
        ]
    },
    {
        'id': 2,
        'marca': 'Kia',
        'titulolinea': 'Kia Rio',
        'modelos': [
            {
                'imagen': 'Kia Rio 2012-2013.png',
                'titulo': 'Kia Rio',
                'modelo': '2012 - 2013',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Kia Rio 2014-2015.png',
                'titulo': 'Kia Rio',
                'modelo': '2014 - 2015',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Kia Rio 2016-2017.png',
                'titulo': 'Kia Rio',
                'modelo': '2016 - 2017',
                'url': 'vehiculo',
            },
        ]
    },
    {
        'id': 4,
        'marca': 'Mazda',
        'titulolinea': 'Mazda 2',
        'modelos': [
            {
                'imagen': 'Mazda 2 2011-2012.png',
                'titulo': 'Mazda 2',
                'modelo': '2011 - 2012',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Mazda 2 2013-2014.png',
                'titulo': 'Mazda 2',
                'modelo': '2013 - 2014',
                'url': 'vehiculo',
            },
        ]
    },
    {
        'id': 5,
        'marca': 'Scion',
        'titulolinea': 'Scion IA',
        'modelos': [
            {
                'imagen': 'Scion IA 2016-2018.png',
                'titulo': 'Scion IA',
                'modelo': '2016 - 2018',
                'url': 'vehiculo',
            },
        ]
    },
    {
        'id': 6,
        'marca': 'Scion',
        'titulolinea': 'Scion XA',
        'modelos': [
            {
                'imagen': 'Scion XA 2004-2006.png',
                'titulo': 'Scion XA',
                'modelo': '2004 - 2006',
                'url': 'vehiculo',
            },
        ]
    },
    {
        'id': 7,
        'marca': 'Scion',
        'titulolinea': 'Scion XB',
        'modelos': [
            {
                'imagen': 'Scion XB 2004-2006.png',
                'titulo': 'Scion XB',
                'modelo': '2004 - 2006',
                'url': 'vehiculo',
            },
        ]
    },
    {
        'id': 7,
        'marca': 'Scion',
        'titulolinea': 'Scion XD',
        'modelos': [
            {
                'imagen': 'Scion XD 2008-2009.png',
                'titulo': 'Scion XD',
                'modelo': '2008 - 2009',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Scion XD 2010-2011.png',
                'titulo': 'Scion XD',
                'modelo': '2010 - 2011',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Scion XD 2012-2013.png',
                'titulo': 'Scion XD',
                'modelo': '2012 - 2013',
                'url': 'vehiculo',
            },
        ]
    },
    {
        'id': 7,
        'marca': 'Toyota',
        'titulolinea': 'Toyota Yaris',
        'modelos': [
            {
                'imagen': 'Toyota Yaris 2007-2008.png',
                'titulo': 'Toyota Yaris',
                'modelo': '2007 - 2008',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Toyota Yaris 2009-2010.png',
                'titulo': 'Toyota Yaris',
                'modelo': '2009 - 2010',
                'url': 'vehiculo',
            },
            {
                'imagen': 'Toyota Yaris 2011-2012.png',
                'titulo': 'Toyota Yaris',
                'modelo': '2011 - 2012',
                'url': 'vehiculo',
            },
        ]
    },
];




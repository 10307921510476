import { modelosmarcas, encabezadomarca} from "../../components/Contenido/MarcaContenido"
import { useParams, Link } from 'react-router-dom';

export default function Marca(){
    const { marca } = useParams();

    // Filtrar los modelosmarcas que coincidan con la marca
    const modelosFiltrados = modelosmarcas.filter(modelo => modelo.marca === marca);
    const encabezado = encabezadomarca.find(data => data.marca === marca);
    
    return(
        <>
       



        <div className="container-fluid team py-5">
        {modelosFiltrados.map((item, index)=>(
            <div className="container py-5">
                <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
                    <h1 className="display-3 mb-4">{item.titulolinea}</h1>
                </div>
                <div className="row g-4 justify-content-center">
                {item.modelos.map((modelo, index)=>(
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                        <Link to={`/${modelo.url}/${modelo.titulo}/${modelo.modelo}`} className="">
                        <div className="team-item rounded">
                            <div className="team-img rounded-top h-100">
                                <img src={`${process.env.PUBLIC_URL}/design/img/marca/modelos/${modelo.imagen}`} className="img-fluid rounded-top w-100" alt=""/>
                            </div>
                            <div className="team-content text-center border border-primary border-top-0 rounded-bottom p-4">
                                <h5>{modelo.titulo}</h5>
                                <p className="mb-0">{modelo.modelo}</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                ))}
                </div>
            </div>

        ))}
        </div>
        
        </>
    )
}